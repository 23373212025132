.checkbox {
    height: 0;
    width: 0;
    visibility: hidden;
}
  
.label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 2em;
    height: .5em;
    background: var(--gray);
    border-radius: 100px;
    position: relative;
}

.label .button {
    content: '';
    position: absolute;
    width: 1em;
    height: 1em;
    border-radius: 100%;
    transition: .3s;
    background: var(--purple);
    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.checkbox:checked + .label .button {
    transform: translateX(100%);
}

.checkbox:checked + .label {
    background: var(--darkerpurple);
}

.label:active .button {
    width: 1em;
}
