html, body, #root {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}


.shadow {
  -webkit-box-shadow: 10px 0px 43px -21px rgba(0,0,0,0.45);
  -moz-box-shadow: 10px 0px 43px -21px rgba(0,0,0,0.45);
  box-shadow: 10px 0px 43px -21px rgba(0,0,0,0.45);
}

.shadowHover {
  -webkit-box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0); 
  box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0);
}

.shadowLight {
  -webkit-box-shadow: 10px 0px 43px -15px rgba(0,0,0,0.45);
  -moz-box-shadow: 10px 0px 43px -15px rgba(0,0,0,0.45);
  box-shadow: 10px 0px 43px -15px rgba(0,0,0,0.45);
}


.shadowDown {
  -webkit-box-shadow: 0px 14px 13px -13px rgba(0,0,0,0.54);
  -moz-box-shadow: 0px 14px 13px -13px rgba(0,0,0,0.54);
  box-shadow: 0px 14px 13px -13px rgba(0,0,0,0.54);
}

:root {
  --black: rgb(0, 0, 0);
  --white: #ffffff;
  --gray: #a1a1a1;
  --lighterGray: #d5d5d5;
  --lighterMoreGray: #ebebeb;
  --lightpurple: #b30066c6;
  --purple: #890350;
  --darkerpurple: #5f0239;
  --orange: #f38713;
}

.menuHover {
  display: flex;
  justify-content: flex-start;
  font-size: 1.5em;
  padding-left: 1em;
  cursor: pointer;
  padding-top: .5em;
  padding-bottom: .5em;
  transition: background-color .5s ease;
  text-decoration: none;
  color: black;
}
.hover {
  padding: .3em;
  transition: background-color .5s ease;
}

.hover:hover {
  background-color: var(--lighterGray);
}

.menuHover:hover {
  background-color: var(--lighterGray);
}

.button {
  background-color: var(--purple);
  border-radius: 20px;
  padding: 1.1em 1em 1.1em 1em;
  color: white;
  cursor: pointer;
  font-weight: 700;
  text-decoration: none;
}

.button:hover {
  background-color: var(--darkerpurple);
  transform: translateY(2px);
}

.button:active {
  transform: translateY(5px);
}

.inputParking {
  border-radius: 10px;
  margin-right: 2em;
  margin-bottom: 1.2em;
  font-size: 1.2em;
  padding: .3em .5em .3em .5em;
  border-width: .2em;
  border-color: var(--purple);
}


*:focus {
  outline: none;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-moz-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-webkit-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-o-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-ms-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.search {
  display: flex;
  gap: ".5em";
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-direction: column;
}
.search::after{
  content: '';
  background-color: gray;
  height: 2px;
  width: 100%;
}

.search:focus-within::after {
  animation: animationSearch 1.5s ease-in-out;
  background-color: var(--purple);
  height: 3px;
}

.searchInside {
  font-size: 1.2em;
  color: black;
  align-self: center;
}

.search:focus-within > div > .searchInside {
  color: var(--purple);
}

@keyframes animationSearch {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
} 